<template>
    <v-container id="dashboard" fluid tag="section">
      <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify" v-model="search">
      </v-text-field>
  
      <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> {{ $t("Drawer.inputsSubects") }} </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="890px">
  
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs" v-on="on">
                  {{ $t("add_new") }}
                </v-btn>
              </template>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step editable :complete="e1 > 1" v-if="editedIndex > -1" step="1" style="    color: #fff;">
                    {{formTitle}}
                  </v-stepper-step>
                  <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                    {{formTitle}}
                  </v-stepper-step>
  
  
  
                </v-stepper-header>
  
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card class="mb-12" flat>
  
                      <v-form ref="form" v-model="valid" lazy-validation>
  
  
  
                        <v-card-text>
                          <v-container>
                        <v-row>
                            <!-- spec -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.spec.ar" :rules="messageRules" required
                                  :label="' الوصف '+$t('ar')" outlined>
                                </v-text-field>
                              </v-col>
  
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.spec.en" 
                                  :label="' الوصف '+$t('en')" outlined>
                                </v-text-field>
                              </v-col>



                              <!-- val -->
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.val.ar" :rules="messageRules" required
                                  :label="' القيمة '+$t('ar')" outlined>
                                </v-text-field>
                              </v-col>
  
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-text-field v-model="editedItem.val.en" 
                                  :label="' القيمة '+$t('en')" outlined>
                                </v-text-field>
                              </v-col>
  

                            
  
                              <v-col class="py-0" cols="12" sm="12" md="12">
                                <v-container fluid>
                                  <v-col class="d-flex" cols="12" sm="6">
                                    <v-select v-model="editedItem.subjects_id" required :rules="select"
                                      item-value="id" item-text="title" :items="category" label="Catgeory Subjects">
                                    </v-select>
                                  </v-col>
  
                                </v-container>
                              </v-col>
                           
  
  
  
                            </v-row>
                          </v-container>
                        </v-card-text>
  
                      </v-form>
                    </v-card>
  
                    <v-btn color="primary" :loading="loadSave" @click="save()">
                      {{$t("save")}}
                    </v-btn>
  
                    <v-btn text @click="close()">
                      {{$t("close")}}
                    </v-btn>
                  </v-stepper-content>
  
                  <v-stepper-content step="2">
                    <UploudeImages :vid="editedItem" :images="editedItem.images" />
  
                    <v-btn text color="red" @click="close()">
                      {{$t("exist")}}
                    </v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-dialog>
          </v-toolbar>
        </template>
  
        <template v-slot:[`item.actions`]="{ item }">
  
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button class="ml-5" @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">{{ $t("edit") }}  </button>
            </template>
            <span>{{ $t("edit") }} </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <button @click="deleteItem(item.id)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                {{$t('Delete')}}</button>
            </template>
            <span>{{$t('Delete')}}</span>
          </v-tooltip>
  
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
        </template>
      </v-data-table>
  
    </v-container>
  
  
  </template>
  
  <script>
    import axios from "axios";
    import Swal from "sweetalert2";
  
    export default {
  
      name: "inputsSubjects",
      data() {
        return {
          desserts: [],
          category: [],
          file_name: '',
  
          book_name: "",
          book_url: "",
          book_file: "",
  
  
          file: "",
          e1: 1,
          imagesUrl: '',
          imageName: '',
          imageUrl: '',
          img_old: '',
          imageFile: '',
          imagesName: '',
  
  
          image_url: '',
          dialog: false,
          loadSave: false,
          show_loading: false,
          x:this.$i18n.locale,

          editedIndex: -1,
          editedItem: {
            image: {
              image_name: ''
            },
            id: "",
            val: {
              ar: "",
              en: ""
            },
            spec: {
              ar: "",
              en: ""
            },
        
            subjects_id: "",
          },
  
  
          headers: [{
              text: 'id',
              value: 'id'
            }, 
            {
              text: 'الرقم المتحفي',
              align: "start",
              value: 'subjects_id'
            },
            {
              text: this.$t('datatable.title'),
              align: "start",
              value: this.$i18n.locale  == 'en' ? "val.en" : "val.ar"
            },
            {
              text: this.$t('Processes'),
              value: "actions",
              sortable: false
            }
          ],
           
  
          right: null,
          vvv: {},
          items: [],
  
  
          valid: false,
          rules: {
            required: value => !!value || this.$t("required_field"),
          },
  
          message: '',
          select: [v => !!v || this.$t("selectq")],
          messageRules: [
            v => !!v || this.$t("valMessage")
          ],
  
        }
      },
      watch:{
            '$i18n.locale': function(newVal, oldVal) {
            
            this.x=newVal;
            this.get();  
  },  },
      methods: {
        get(){
                return this.headers= [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },
                    {
                        text: 'الرقم المتحفي',
                        align: "start",
                        value: 'subjects_id'
                    },
                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value: this.x == 'en' ? "spec.en" : "spec.ar"
                    },
                    {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },
        validate() {
          valid: true;
        },
        initialize() {
          this.loading = true;
          try {
            axios.get("inputsSubjects", {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              }).then(res => {
                this.loading = false;
                this.desserts = res.data.data;
  
              })
              .catch(() => {
                this.loading = false;
              });
          } catch (e) {
            console.log(e);
          }
        },
      
        editItem(item) {
          // console.log(item);
          this.dialog = true;
          this.e1 = 1;
          this.editedIndex = this.desserts.indexOf(item);
          this.$store.dispatch("book_uploads", item);
          this.editedItem = Object.assign({}, item);
       

  
      
        },
        close() {
          this.dialog = false;
          this.e1 = 1;
          this.loadSave=false;
  
  
          this.editedItem = {
           
            id: "",
            val: {
              ar: "",
              en: ""
            },
            spec: {
              ar: "",
              en: ""
            },
        
            subjects_id: "",
  
          };
          this.$refs.form.resetValidation();
  
        },
  
       
  
  
  
        deleteItem($id) {
  
          Swal.fire({
            title: this.$t('sure_process'),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: this.$t('yes'),
            cancelButtonText: this.$t('no'),
          }).then(result => {
            if (result.value) {
              axios.delete("inputsSubjects/" + $id, {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    authorizations: "Bearer " + this.$store.state.AdminInfo.token
                  }
                })
                .then(() => {
                  this.initialize();
                  Swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                })
                .catch(() => {
                  this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                });
            }
          });
        },
        getCats() {
  
          axios.get("/subjects", {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            })
            .then(res => {
              this.category = res.data.data;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        },
        save() {
  
           if (this.$refs.form.validate()) {
              if (this.editedIndex > -1) {
                this.loadSave = true;
  
                var update = {
                    translations: [{
                        val: this.editedItem.val.ar,
                        spec: this.editedItem.spec.ar,
  
                      },
                      {
                        val: this.editedItem.val.en,
                        spec: this.editedItem.spec.en,
  
                      }
                    ],
                   subjects_id: this.editedItem.subjects_id,
  
                }
                this.axios
                  .put("inputsSubjects/update/" + this.editedItem.id, update, {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                    },
                  })
                  .then(() => {
  
                  this.$refs.form.resetValidation();
  
                    this.initialize();
                    this.close();

  
                    Swal.fire({
                      title: "تم تعديل ",
                      text: "",
                      icon: "success",
                      confirmButtonText: "اغلاق",
                    });
                  })
                  .catch(() => {
                    this.loadSave = false;
  
                    Swal.fire({
                      title: "تاكد من ملى المعلومات",
                      text: "",
                      icon: "error",
                      confirmButtonText: "اغلاق",
                    });
                  });
              } else {
   
  
                  this.show_loading=true;
                  var savedate = {
  
                    translations: [{
                        val: this.editedItem.val.ar,
                        spec: this.editedItem.spec.ar,
  
                      },
                      {
                        val: this.editedItem.val.en,
                        spec: this.editedItem.spec.en,
  
                      }
                    ],
                   subjects_id: this.editedItem.subjects_id,
  
                  }
  
                  axios
                    .post("inputsSubjects", savedate, {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                      },
                    })
                    .then((res) => {
                      this.editedItem = res.data.data;
                      this.editedIndex = -1;
                      this.$refs.form.resetValidation();
  
                      this.dialog = false;
                                  this.loadSave = false;
                            
                                  this.show_loading=false;
                                  this.close();
                                  this.initialize();
                                  Swal.fire({
                      title: "تم الرفع ",
                      text: "",
                      icon: "success",
                      confirmButtonText: "اغلاق",
                    });
                  
                    })
  
                    .catch((err) => {
                      err
  
                      this.loadSave = false;
  
                    });
                }
              }
        },
      },
      components: {
  
      },
      computed: {
        formTitle() {
          return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');
  
        },
      },
  
      created() {
        this.initialize();
        this.getCats();
        this.get();
      }
  
    }
  </script>
  <style>
  .v-overlay--active {
      z-index: 0 !important;
  }
  
  </style>